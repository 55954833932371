import { useMemo } from 'react'
import NextScript from 'next/script'
import reduce from 'lodash/reduce'
// Hooks
import htmlReactParser from '@/hooks/useHtmlReactParser'
// Interface
import ScriptProps from '@/interfaces/components/script.interface'
import { useCookieConsentCheck } from '@/utils/hooks'

const Script = (props: ScriptProps) => {
  const isAccepted = useCookieConsentCheck('google_analytics')
  const scriptData = useMemo(
    () => reduce(props.data, (data, { value, key }) => Object.assign(data, { [`data-${key}`]: value }), {}),
    [props.data],
  )

  // will use to check condition if script is loading external or inline script
  const isExternalScript = !!props.src

  const handleScript = () => {
    if (props.awaitCookieConsent && !isAccepted) return
    if (isExternalScript) {
      return <NextScript id={props.id} src={props.src} strategy={props.strategy} async={props.async} {...scriptData} />
    }
    if (!isExternalScript && props.javascriptCode) {
      return <NextScript id={props.id} strategy={props.strategy} type={props.type}>{`${props.javascriptCode}`}</NextScript>
    }

    return
  }

  const handleNoScript = () => {
    if (!props.noscriptCode) return
    if (props.awaitCookieConsent && !isAccepted) return

    return htmlReactParser(`<noscript>${props.noscriptCode}</noscript>`)
  }

  return (
    <>
      {handleScript()}
      {handleNoScript()}
    </>
  )
}

export default Script

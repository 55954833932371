/** @type {import('tailwindcss').Config} */
module.exports = {
  content: [
    './node_modules/flowbite/**/*.js',
    './node_modules/flowbite-react/lib/esm/**/*.js',
    './public/**/*.html',
    './src/**/**/*.{ts,tsx}',
  ],
  safelist: [
    'heading-3',
    {
      pattern: /^bg-v2-background-(white|blue|blue-darker|beige|green-dark|green-darker)$/,
      variants: ['xl', 'lg', 'md'],
    },
    {
      pattern: /\[\&\_path\]\:!fill-(v2-primary-static-1|white|primary)$/,
    },
    {
      pattern: /^grid-cols-(\d)$/,
      variants: ['xl', 'lg', 'md'],
    },
  ],
  theme: {
    extend: {
      typography: () => ({
        DEFAULT: {
          css: {
            maxWidth: '100%',
          },
        },
      }),
      text: {
        'body-1': {
          default: '23px',
        },
        'body-2': {
          default: '18px',
        },
      },
      colors: {
        v2: {
          primary: {
            // Green-dark
            'static-1': '#0B7D51',
            'static-2': '#7CAF98',
            'static-3': '#BCD7CB',
          },
          secondary: {
            'static-1': '#FAF2E899',
            'static-2': '#FAF2E8',
            'static-3': '#FB9903',
          },
          background: {
            white: '#FFFFFF',
            'green-dark': '#0B7D51',
            'green-darker': '#096240',
            blue: '#DDEAF1',
            'blue-darker': '#C5DBE7',
            beige: '#FAF2E8',
            'beige-pale': '#FAF2E860',
            'surface-3': '#F1EAE2',
          },

          text: {
            primary: '#2D4155',
            secondary: '#2d4155bf',
          },
          foreground: {
            'static-tertiary': '#2d415533',
            'static-secondary': '#2d4155bf',
          },
          surface: {
            2: '#faf2e899',
          },
        },
        primary: {
          50: '#EEF6F3',
          100: '#E4F1EC',
          200: '#D7EAE2',
          400: '#A5CFBF',
          500: '#85BEA8',
          600: '#3C9774',
          700: '#0B7D51',
        },
        orange: {
          200: '#FED69A',
          500: '#FCB64A',
          600: '#FCAA2B',
          700: '#FB9903',
        },
        green: {
          100: '#DEF7EC',
          400: '#A5CFBF',
          500: '#0E9F6E',
          700: '#046C4E',
        },
        darkGrey: '#283035',
        gray: {
          100: '#F3F4F6',
          200: '#E5E7EB',
          500: '#6B7280',
          900: '#111928',
        },
        blue: {
          200: '#2D9CDB',
        },
        overlay: 'rgba(31, 42, 55, 0.5)',
      },
      fontFamily: {
        lato: ['var(--font-lato)', 'sans-serif'],
        bitter: ['var(--font-bitter)', 'sans-serif'],
      },
      boxShadow: {
        header: '0px 2px 4px -2px rgba(0, 0, 0, 0.05), 0px 4px 6px -1px rgba(0, 0, 0, 0.10)',
        'accordion-card': '0px 4px 6px 0px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.10)',
        'help-card-cta': '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
        'diagnosis-selection-card': '0px 10px 10px 0px rgba(0, 0, 0, 0.04), 0px 20px 25px -5px rgba(0, 0, 0, 0.10)',
      },
      gridTemplateColumns: {
        '1/1': '1fr 1fr',
        '1/2': '1fr 2fr',
        '2/1': '2fr 1fr',
      },
      borderRadius: {
        // V2
        '5xl': '2.1875rem',
      },
      width: {
        34: '8.5rem',
        50: '12.5rem',
        121.5: '30.375rem',
      },
      height: {
        15: '3.75rem',
        67: '16.75rem',
        169: '42.25rem',
        128: '32rem',
      },
      minHeight: {
        114: '28.5rem',
      },
      minWidth: {
        auto: 'auto',
        192: '12rem',
      },
      maxWidth: {
        7.5: '1.875rem',
        37.5: '9.375rem',
        100: '25rem',
      },
      spacing: {
        18: '4.5rem',
        26: '6.5rem',
        32.5: '8.125rem',
        35: '8.75rem',
      },
      blur: {
        '1.5xl': '2.25rem',
      },
    },
  },
  plugins: [
    require('@tailwindcss/typography'),
    require('flowbite/plugin'),
    function ({ addBase, theme }) {
      function extractScreenVars(screens) {
        return Object.keys(screens).reduce((vars, screenKey) => {
          const key = `--screen-${screenKey}`

          return { ...vars, [key]: screens[screenKey] }
        }, {})
      }

      function extractColorVars(colors, colorGroup = '') {
        return Object.keys(colors).reduce((vars, colorKey) => {
          const value = colors[colorKey]
          const cssVariable = colorKey === 'DEFAULT' ? `--color${colorGroup}` : `--color${colorGroup}-${colorKey}`

          const newVars = typeof value === 'string' ? { [cssVariable]: value } : extractColorVars(value, `-${colorKey}`)

          return { ...vars, ...newVars }
        }, {})
      }

      addBase({
        ':root': {
          ...extractScreenVars(theme('screens')),
          ...extractColorVars(theme('colors')),
        },
      })
    },
  ],
}

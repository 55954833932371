import React from 'react'
import Script from 'next/script'
import get from 'lodash/get'
import parse, { Element, DOMNode, attributesToProps } from 'html-react-parser'

const useHtmlReactParser = (str: string) => {
  const parserReplace = (domNode: DOMNode) => {
    const typeDomNode = domNode as Element
    if (domNode.type === 'script') {
      const props = attributesToProps(typeDomNode.attribs)
      return <Script {...props}>{`${get(typeDomNode.children, '[0].data')}`}</Script>
    }
  }

  return parse(str, { replace: parserReplace })
}

export default useHtmlReactParser

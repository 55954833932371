import { useEffect, useState } from 'react'
import reduce from 'lodash/reduce'
import forEach from 'lodash/forEach'
import resolveConfig from 'tailwindcss/resolveConfig'
import tailwindConfig from '../../tailwind.config.js'

type UseScreenSize = {
  sm?: boolean
  md?: boolean
  lg?: boolean
  xl?: boolean
  '2xl'?: boolean
}

export const useScreenSize = (): UseScreenSize => {
  const [screenSize, setScreenSize] = useState({})
  const fullConfig = resolveConfig(tailwindConfig)

  useEffect(() => {
    const queries: { [key: string]: MediaQueryList } = reduce(
      fullConfig.theme?.screens,
      (res, val, key) => {
        return { ...res, [key]: window.matchMedia(`(max-width: ${val})`) }
      },
      {},
    )

    const getScreenSizeMatches = () => {
      return reduce(
        queries,
        (res, val, key) => {
          return { ...res, [key]: val.matches }
        },
        {},
      )
    }

    const handler = () => setScreenSize(getScreenSizeMatches())
    handler()

    forEach(queries, (val) => {
      val.addEventListener('change', handler)
    })

    return () => {
      forEach(queries, (val) => {
        val.removeEventListener('change', handler)
      })
    }
  }, [fullConfig.theme?.screens])

  return screenSize
}

declare global {
  interface Window {
    _axcb: Array<unknown>
  }
}

type Choices = 'google_analytics' | 'youtube'
type Cookies = {
  [key in Choices]: boolean
}
type Axeptio = {
  on: (arg0: string, arg1: (choices: Cookies) => void) => void
}

export const useCookieConsentCheck = (cookieName: Choices): boolean => {
  const [isAccepted, setIsAccepted] = useState(false)
  useEffect(() => {
    void 0 === window._axcb && (window._axcb = [])
    window._axcb.push((axeptio: Axeptio) => {
      axeptio.on('cookies:complete', (choices: Cookies) => {
        if (choices[cookieName]) setIsAccepted(true)
      })
    })
  }, [cookieName])

  return isAccepted
}
